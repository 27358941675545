<template>
    <section class="technology-2 py-5 overflow-hidden">
        <div class="container">
            <h1 class="display-3 font-weight-bolder text-center mb-4 font-noto-serif-tc">
                高速脫毛技術
            </h1>

            <h3 class="text-center mb-4">
                1分鐘UPS超短脈衝 使每根毛髮充分加熱
            </h3>

            <div class="row">
                <div class="col-md-6 mb-3 mb-md-0">
                    <img class="w-100 image-tech"
                         alt="高速脫毛技術"
                         src="../../../assets/campaign/elysion-pro-comb/technology-2.svg">
                </div>
                <div class="col-md-6 d-flex flex-column justify-content-center">
                    <h3 class="mb-0 font-weight-bolder">
                        A - 25°C
                    </h3>
                    <h5 class="font-weight-normal mb-3">
                        晶體冷凍系統，將皮膚溫度降低 25°C
                    </h5>

                    <h3 class="mb-0 font-weight-bolder">
                        B - 45°C
                    </h3>
                    <h5 class="font-weight-normal mb-3">
                        皮膚溫度增加45°C
                    </h5>

                    <h3 class="mb-0 font-weight-bolder">
                        C - 70/80°C
                    </h3>
                    <h5 class="font-weight-normal mb-3">
                        毛囊達到 70-80°C，在基座處引起微燒傷
                    </h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ElysionProCombTechnology2",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .technology-2 {
        background-color: $background-color;
        color: $text-color;

        .image-tech {
            border: solid 10px #e57a30;
            border-radius: 15px;
        }
    }
</style>
