<template>
    <section class="global-usage py-5">
        <div class="container">
            <h1 class="display-4 font-weight-bolder text-center mb-5 font-noto-serif-tc">
                100萬真實用家見證
            </h1>
            <div class="position-relative global-map"
                 v-observe-visibility="{ callback: visibilityChanged, once: true }">
                <img class="w-100"
                     alt="100萬真實用家見證"
                     src="../../../assets/campaign/elysion-pro-comb/map.svg">
                <div class="position-absolute odometer-wrapper">
                    <h1 class="display-3 font-weight-bolder">
                        <odometer v-bind:value="counter"
                                  v-bind:duration="5000">
                        </odometer>
                    </h1>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ObserveVisibility } from "vue-observe-visibility";
    import Odometer from "./components/Odometer";

    export default {
        name: "ElysionProCombGlobalUsage",
        components: {
            Odometer
        },
        directives: {
            "observe-visibility": ObserveVisibility
        },
        data () {
            return {
                counter: 0,
            };
        },
        computed: {

        },
        methods: {
            visibilityChanged (isVisible) {
                console.log(isVisible);
                if (isVisible) {
                    this.counter = 1000000;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .global-usage {
        background-color: $background-color-alt;
        color: $text-color;

        .global-map {
            .odometer-wrapper {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                & > h1 {
                    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
</style>
