<template>
    <span v-bind:class="className"></span>
</template>

<script>
    const Odometer = require("odometer/odometer.min.js");
    import "odometer/themes/odometer-theme-minimal.css";

    export default {
        name: "Odometer",
        props: {
            className:{
                type: String,
                default: "odometer"
            },

            value: {
                type: Number,
                default: 0
            },

            format: {
                type: String,
                default: "(,ddd).dd"
            },

            theme: {
                type: String,
                default: "minimal"
            },

            duration: {
                type: Number,
                default: 3000
            },

            animation: {
                type: String,
                default: "count"
            },

            formatFunction: {
                type: Function,
            }
        },
        data () {
            return {
                instance: null
            }
        },
        mounted () {
            this.instance = new Odometer({
                el: this.$el,
                value: this.value,
                theme: this.theme,
                format: this.format,
                duration: this.duration,
                animation: this.animation
            });

            this.instance.render();
        },
        watch: {
            value (newVal) {
                if (
                    this.instance &&
                    this.instance.update
                ) {
                    this.instance.update(newVal);
                }
            }
        },
    }
</script>
