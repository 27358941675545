<template>
    <article>
        <elysion-pro-comb-home-1></elysion-pro-comb-home-1>
        <elysion-pro-comb-home2></elysion-pro-comb-home2>
        <elysion-pro-comb-features></elysion-pro-comb-features>
        <elysion-pro-comb-technology1></elysion-pro-comb-technology1>
        <elysion-pro-comb-technology2></elysion-pro-comb-technology2>
        <elysion-pro-comb-global-usage></elysion-pro-comb-global-usage>
        <elysion-pro-comb-promo-counter v-model="campaign.totalEnquiry"></elysion-pro-comb-promo-counter>
        <enquiry duplicate-to-g-sheet
                 g-sheet-id-key="branch"
                 v-bind:g-sheet-id="gSheetIds"
                 v-bind:treatment-id="treatmentId">
            <span class="font-noto-serif-tc">
                免費專業美容顧問咨詢
            </span>
        </enquiry>
    </article>
</template>

<script>
    import Enquiry from "./Enquiry";
    import ElysionProCombHome1 from "./ElysionProComb/Home1";
    import ElysionProCombHome2 from "./ElysionProComb/Home2";
    import ElysionProCombTechnology1 from "./ElysionProComb/Technology1";
    import ElysionProCombTechnology2 from "./ElysionProComb/Technology2";
    import ElysionProCombFeatures from "./ElysionProComb/Features";
    import ElysionProCombPromoCounter from "./ElysionProComb/PromoCounter";
    import ElysionProCombGlobalUsage from "./ElysionProComb/GlobalUsage";
    export default {
        name: "ElysionProComb",
        components: {
            ElysionProCombGlobalUsage,
            ElysionProCombPromoCounter,
            ElysionProCombFeatures,
            ElysionProCombTechnology2,
            ElysionProCombTechnology1,
            ElysionProCombHome2,
            ElysionProCombHome1,
            Enquiry
        },
        metaInfo () {
            return {
                title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
                meta: [{
                    "name": "description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"

                }*/]
            }
        },
        props: {
            treatmentId: {
                type: String,
                required: true,
            }
        },
        data () {
            return {
                gSheetIds: {
                    "香港銅鑼灣羅素街38號金朝陽中心9樓902-903室": "AKfycbx5tef8q9huChgYmK5bLO65mCL_HEfbBQMzN8HC94LVOTXxcAfe", // CWB
                    "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8" // TST
                },
            };
        },
        computed: {
            campaign () {
                let target = this.$store.state.mall.campaigns.find(
                    campaign => campaign.treatmentId === this.treatmentId
                );

                if (!target) {
                    return {};
                }

                return target;
            },

            treatment () {
                let target = this.$store.state.mall.salon.treatments.find(
                    treatment => treatment._id === this.treatmentId
                );
                if (target) {
                    return target;
                }
                return {
                    title: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    subtitle: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    availableBranches: [],
                    images: [],
                    cover: "",
                };
            },
        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
    @import url("https://fonts.googleapis.com/css?family=Noto+Serif+TC:900&display=swap");

    .font-noto-serif-tc {
        font-family: "Noto Serif TC", serif;
    }
</style>
